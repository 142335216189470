
<template>
  <modal
    :visible="value"
    @onClosed="doClose"
    size="xsmall"
  >
    <span slot="head">{{ $t(162) }}</span>
    <div slot="body">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="nickname">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
        </el-form>
    </div>
    <div slot="foot">
      <div class="text-center">
        <el-button @click="doClose">{{ $t(164) }}</el-button>
        <el-button type="primary" :loading="loading" @click="updateNickname">{{ $t(127) }}</el-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import { editUserInfo } from '@/api'
import utils from '@/utils/misc'
export default {
  name: 'SettingNickname',
  components: {Modal},
  props: {
    value: Boolean
  },

  data() {
    return {
      loading: false,
      form: {
        nickname: this.$store.state.userInfo.nickname
      }
    }
  },
  beforeMount() {
  },
  computed: {
    rules() {
      return {
        nickname: [{
          validator: (rule,value,callback) => {
            if (value==='') {
              return callback(new Error(this.$t('163')))
            }
            callback()
          }
        }]
      }
    }
  },
  methods: {
    doClose() {
      this.$emit('input', false)
    },
    updateNickname() {
      this.$refs.form.validate(valid => {
        if(valid) {
          this.loading = true
          editUserInfo(this.form).then(res => {
            this.doClose()
            this.$store.dispatch('getUserInfo')
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
    .lang-item{
        display: inline-block;
        margin-top: 16px;
        margin-right: 16px;
        width: calc(50% - 16px);
        height: 40px;
        line-height: 40px;
        padding: 0px 12px;
        &:hover{
            border-radius: 4px;
            background-color: #f5f5f5;
        }
        &.active{
            color: $primaryColor;
        }
    }
</style>