
<template>
  <modal
    :visible="value"
    @onClosed="doClose"
    size="xsmall"
  >
    <span slot="head">{{ $t(145) }}</span>
    <div slot="body">
        <div class="lang-item cursor" v-for="item in currencies" :key="item.currency" :class="{active: curCurrency===item.currency}" @click="switchCurrency(item)">
            {{item.currency}}
        </div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import { updateValuation } from '@/api'
export default {
  name: 'PriceUnitSetting',
  components: {Modal},
  props: {
    value: Boolean
  },

  data() {
    return {
        curCurrency: '',
    }
  },
  computed: {
    currencies() {
      return this.$store.state.currencies
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  beforeMount() {
    this.curCurrency = this.userInfo.valuationMethod || window.configObj.defaultCurrency
  },
  methods: {
    switchCurrency(obj) {
        updateValuation({ currency: obj.currency }).then(res => {
          this.curCurrency = obj.currency
          this.$store.dispatch('getUserInfo')
        })
    },
    doClose() {
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
    .lang-item{
        display: inline-block;
        margin-top: 16px;
        margin-right: 16px;
        width: calc(33% - 16px);
        height: 40px;
        line-height: 40px;
        padding: 0px 12px;
        &:hover{
            border-radius: 4px;
            background-color: #f5f5f5;
        }
        &.active{
            color: $primaryColor;
        }
    }
</style>