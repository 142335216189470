<template>
  <div class="userInfo">
    <div class="baseMsg">
      <div class="ml flex align-center">
        <img-uploader v-model="avatar" @update="editAvatar" :width="80" :height="80" style="border-radius: 100%;overflow: hidden;margin-right: 20px;"></img-uploader>
        <div class="flex1">
          <div class="hi">Hi, {{ userInfo.nickname }} <el-button type="text" class="el-icon-edit" @click="showSettingNickname=true"></el-button> </div>
          <div class="uid">uid {{ userInfo.uuid }}</div>
        </div>
        <el-button type="text" @click="logout">{{ $t(306) }}</el-button>
      </div>
    </div>
    <div class="others">
      <div class="ml">
        <div class="flex align-center ">
          <div class="flex1 pd border safe">
            <h3>{{ $t(129) }}</h3>
            <p class="desc">{{ $t(130) }}</p>
            <div class="mt10">
              <div v-if="verifyStatus === 2">
                <el-button type="text" >{{ $t(161) }}</el-button><el-button class="el-icon-check" style="font-weight: bold;" type="text"></el-button>
              </div>
              <div v-else-if="verifyStatus === 3">
                <span class="danger mt10">{{ $t(319) }}</span><span class="ml10 color999 fs12">{{ rejectVerifyReason }}</span>
                <div><el-button type="text" @click="$router.push({name: 'AuthVerify'})">{{ $t(320) }}</el-button></div>
              </div>
              <el-button type="text" v-else-if="verifyStatus==1">{{ $t(178) }}</el-button>
              <el-button type="text" v-else @click="$router.push({name: 'AuthVerify'})">{{ $t(131) }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="ml">
        <div class="board border">
          <div class="head">{{ $t(132) }}</div>
          <div class="content">
            <div class="flex align-center item" v-if="configObj.accountType!=='1'">
              <div class="icon el-icon-message"></div>
              <div class="flex1">
                <h3>{{ $t(101) }}</h3>
                <p class="desc">{{ $t(133) }}</p>
              </div>
              <div class="op">
                <span class="sf" v-if="userInfo.mail">{{ userInfo.mail.replace(/(\d{1})\d+(\d{3})/,'$1****$2') }} <span>{{ $t(134) }}</span> <el-button class="ml10" type="text" @click="$router.push({name: 'UpdateEmail',query:{type: '2'}})">{{ $t(143) }}</el-button></span>
                <el-button v-else type="text" @click="$router.push({name: 'UpdateEmail',query:{type: '1'}})">{{ $t(142) }}</el-button>
              </div>
            </div>
            <div class="flex align-center item" v-if="configObj.accountType!=='2'">
              <div class="icon el-icon-mobile"></div>
              <div class="flex1">
                <h3>{{ $t(102) }}</h3>
                <p class="desc">{{ $t(133) }}</p>
              </div>
              <div class="op">
                <span class="sf" v-if="userInfo.mobile">{{ userInfo.mobile.replace(/(\d{1})\d+(\d{3})/,'$1****$2') }} <span>{{ $t(134) }}</span> <el-button class="ml10" type="text" @click="$router.push({name: 'UpdateMobile',query:{type: '2'}})">{{ $t(143) }}</el-button></span>
                <el-button v-else type="text" @click="$router.push({name: 'UpdateMobile',query:{type: '1'}})">{{ $t(142) }}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="board border">
          <div class="head">{{ $t(135) }}</div>
          <div class="content">
            <div class="flex align-center item">
              <div class="icon el-icon-lock"></div>
              <div class="flex1">
                <h3>{{ $t(136) }}</h3>
                <p class="desc">{{ $t(138) }}</p>
              </div>
              <div class="op">
                <el-button type="text" @click="$router.push({name: 'UpdateLoginPwd'})">{{ $t(143) }}</el-button>
              </div>
            </div>
            <div class="flex align-center item">
              <div class="icon el-icon-lock"></div>
              <div class="flex1">
                <h3>{{ $t(137) }}</h3>
                <p class="desc">{{ $t(139) }}</p>
              </div>
              <div class="op">
                <el-button type="text" @click="$router.push({name: 'UpdateFundsPwd'})">{{ $t(143) }}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="board border">
          <div class="head">{{ $t(312) }}</div>
          <div class="content">
            <div class="flex align-center item">
              <div class="icon el-icon-s-finance"></div>
              <div class="flex1">
                <h3>{{ $t(313) }}</h3>
                <p class="desc">{{ $t(330) }}</p>
              </div>
              <div class="op">
                <el-button type="text" @click="$router.push({name: 'AddWallet'})">{{ $t(143) }}</el-button>
              </div>
            </div>
            <div class="flex align-center item">
              <div class="icon el-icon-s-claim"></div>
              <div class="flex1">
                <h3>{{ $t(1088) }}</h3>
                <p class="desc">{{ $t(331) }}</p>
              </div>
              <div class="op">
                <el-button type="text" @click="$router.push({name: 'BankSetting'})">{{ $t(143) }}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="board border">
          <div class="head">{{ $t(140) }}</div>
          <div class="content">
            <div class="flex align-center item">
              <div class="icon el-icon-setting"></div>
              <div class="flex1">
                <h3>{{ $t(141) }}</h3>
                <p class="desc">{{ $t(144) }}</p>
              </div>
              <div class="op">
                <el-button type="text" @click="showColorMode = true">{{ $t(143) }}</el-button>
              </div>
            </div>
            <div class="flex align-center item">
              <div class="icon el-icon-s-data"></div>
              <div class="flex1">
                <h3>{{ $t(145) }}</h3>
                <p class="desc">{{ $t(146) }}{{$t(147)}}{{configObj.defaultCurrency}}</p>
              </div>
              <div class="op">
                <span class="sf mr10">{{ userInfo.valuationMethod }}</span>
                <el-button type="text" @click="showSetUnit=true">{{ $t(143) }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PriceUnitSetting v-model="showSetUnit"> </PriceUnitSetting>
    <ColorSetting v-model="showColorMode"> </ColorSetting>
    <SettingNickname v-model="showSettingNickname" v-if="showSettingNickname"> </SettingNickname>
  </div>
</template>

<script>
import { editUserInfo, getAuthInfo } from '@/api'
import utils from '@/utils/misc'
import ImgUploader from '@/components/ImgUploader.vue'
import PriceUnitSetting from './components/PriceUnitSetting.vue'
import ColorSetting from './components/ColorSetting.vue'
import SettingNickname from './components/SettingNickname.vue'
export default {
  name: 'UserInfo',
  components: { ImgUploader, PriceUnitSetting, ColorSetting, SettingNickname },
  mixins: [],
  data() {
    return {
      showSetUnit: false,
      showColorMode: false,
      showSettingNickname: false,
      avatar: '',
      form: {
      },
      verifyStatus: false,
      rejectVerifyReason: ''
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
      }
    },
    countryList() {
      return this.$store.state.countryList
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    userInfo() {
      this.avatar = this.userInfo.avatar
    }
  },
  beforeMount() {
    this.avatar = this.userInfo.avatar
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
    getAuthInfo().then(res => {
      this.verifyStatus = res.status
      this.rejectVerifyReason = res.reason
    })
  },
  methods: {
    editAvatar() {
      editUserInfo({ avatar: this.avatar }).then(res => {
        this.$store.dispatch('getUserInfo')
      })
    },
    logout() {
      utils.removeLs('token') 
      this.$router.push({ name: 'Login', query: {redirect: this.$route.path} })
    }
  }
}
</script>

<style lang="scss">
.userInfo{
  .safe{
    background: url(../../assets/img/auth.png) no-repeat;
    background-position: 90% center;
    background-size: 200px;
  }
  .baseMsg{
    padding: 200px 0px 40px 0px;
    background-color: #1d1f20;
    .hi{
      color: #fff;
      font-size: 24px;
    }
    .uid{
      color: #fff;
      display: inline-block;
      padding: 2px 10px;
      min-width: 40px;
      border-radius: 11px;
      border: 1px solid #686868;
    }
  }
  .others{
    padding: 20px 0px;
    margin: 50px 0px;
    h3{
      font-weight: normal;
      margin-bottom: 4px;
      font-size: 15px;
    }
    .desc{
      color: #666;
      font-size: 13px;
    }
    .board{
      margin-top: 24px;
      .head{
        background-color:#f2f6fa;
        padding: 15px 24px;
        border-bottom: 1px solid $borderColor;
        font-size: 16px;
      }
      .content {
        .item{
          padding: 15px 24px;
          &:not(:last-child){
            border-bottom: 1px solid $borderColor;
          }
          .icon{
            color: $primaryColor;
            margin-right: 20px;
            font-size: 18px;
            height: 30px;
            width: 30px;
            line-height: 29px;
            border: 1px solid $primaryColor;
            border-radius: 100%;
            text-align: center;
          }
          .sf{
            color: #999;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>